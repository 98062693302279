@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';
@import 'variables';

quill-editor {

    .ql-snow.ql-toolbar {
      background-color: $stil-red;

      + .ql-container.ql-snow {
        background-color: $white-100;
      }

      .ql-fill,
      .ql-snow .ql-stroke.ql-fill {
        fill: $white-100;
      }

      .ql-stroke {
        stroke: $white-100;
      }

      .ql-picker-label {
        color: $white-100;

        .ql-fill,
        .ql-stroke.ql-fill {
          fill: $white-100;
        }

        .ql-stroke {
          stroke: $white-100
        }

        &:hover {
          background-color: $stil-dark-red;

          .ql-fill,
          .ql-stroke.ql-fill {
            fill: $stil-red-light;
          }

          .ql-stroke {
            stroke: $stil-red-light;
          }
        }
      }

      button {
        color: $white-100;

        &:hover {
          background-color: $stil-dark-red;

          .ql-fill,
          .ql-stroke.ql-fill {
            fill: $stil-red-light;
          }
        }
        &:focus {
          background-color: $stil-dark-red;

          .ql-fill,
          .ql-stroke.ql-fill {
            fill: $stil-red-light;
          }
        }


        &.ql-active {
          background-color: $stil-dark-red;
          color: $stil-red-light;

          .ql-fill,
          .ql-snow .ql-stroke.ql-fill {
            fill: $stil-red-light;
          }

          .ql-stroke {
            stroke: $stil-red-light;
          }
        }

      }


    }

    .ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
      stroke: $stil-red-light;
    }

    .ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
      color: $stil-red-light;
  }
}
/* fix to override base img block display from tailwind */
.ql-align-center img {
  display: inline;
}
