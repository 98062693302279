@use '@angular/material' as mat;
@use '@ngxpert/hot-toast/src/styles/styles.scss';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './assets/styles/globals';
@import './assets/styles/font-faces';
@import './assets/styles/quill-theme';
@import './assets/styles/tailwind_custom';

@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light.css';
@import 'tippy.js/animations/scale.css';

@include mat.all-component-typographies();
@include mat.core();

$safetk-client-primary: mat.m2-define-palette(mat.$m2-red-palette);
$safetk-client-accent: mat.m2-define-palette(mat.$m2-indigo-palette, A200, A100, A400);
$safetk-client-warn: mat.m2-define-palette(mat.$m2-red-palette);
$safetk-client-theme: mat.m2-define-light-theme((
  color: (
    primary: $safetk-client-primary,
    accent: $safetk-client-accent,
    warn: $safetk-client-warn
  ),
  typography: mat.m2-define-typography-config(),
  density: 0
  ));
@include mat.all-component-themes($safetk-client-theme);


@import './assets/styles/variables';
$theme-colors: (primary: $stil-red, secondary: $white-200);
$enable-validation-icons: false;
@import '@fortawesome/fontawesome-free/css/all.css';

@import '../node_modules/@swimlane/ngx-datatable/index.css';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import '../node_modules/@swimlane/ngx-datatable/themes/material';

@import './assets/styles/scrollbar';
@import './assets/styles/datatable-stilwell.scss';


$custom-typography: mat.m2-define-typography-config($font-family: 'Roboto, sans-serif');

@import './assets/styles/overrides';


label.is-invalid {
    color: rgb(180, 0, 0);
}

html, body {
  height: 100vh;
  margin: 0;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: url('./assets/background.png') no-repeat center;
  background-size: cover;
}

/**
  Tailwind preflight sets border-style: solid for all elements.
  https://tailwindcss.com/docs/preflight#border-styles-are-reset-globally
  Material 15 fix
*/


 .mdc-notched-outline__notch {
  border-right: none;
}

.mdc-text-field__input, textarea:focus {
  box-shadow: none;
}

.hot-toast-close-btn {
  background-color: var(--hot-toast-close-btn-background-color, transparent) !important;
  background-image: var(--hot-toast-close-btn-background-image, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")) !important;
}


/**
  mat-button 'color' styles
*/

.mat-success {
  color: #fff !important;
  background-color: #28a745 !important;
}

.mat-success[disabled] {
  color: auto;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-cancel:hover {
  color: #fff !important;
  background-color: red !important;
}

.timepicker {

  .timepicker__header {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    background-color: white!important;
    border-bottom: 1px solid #e5e7eb !important;
  }

  .timepicker-dial__container {
    justify-content: center!important;
  }

  .timepicker-dial__control {
    color:  #f44336 !important;
  }

  button.timepicker-dial__item.timepicker-period__btn {
    color:  #d8a6a4 !important;
  }

  button.timepicker-dial__item.timepicker-period__btn.timepicker-dial__item_active  {
    color:  #f44336 !important;
  }

  .timepicker-dial__time  {
    color:  #f44336 !important;
  }

  .clock-face__clock-hand {
    background-color: #f44336 !important;
    &::after {
      background-color: #f44336 !important;
    }
    &::before {
      border-color: #f44336 !important;
    }
  }

  .clock-face__number {
    > span.active {
      background-color: #f44336 !important;
    }
  }

  .timepicker-button {
    font-family: 'Roboto', sans-serif !important;
    border-radius: 4px !important;
    color: white !important;
    margin-left: 16px !important;
    background-color: #f44336 !important;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  }

  .timepicker-button:hover {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.24);
  }


}
